.photo-gallery, .media-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .main-image ,.main-media{
    margin-bottom: .5rem;
  }
  
  .main-image img {
    max-width: 100%;
    height: auto;
    cursor: pointer;
  }
  .main-media img {
    max-width: 100%;
    max-height:  85vh;
        cursor: pointer;
        border-radius: 40px;

  }
  .main-media video {
    max-width: 100%;
    max-height:  80vh;
    cursor: pointer;
    border-radius: 40px;
  }
  
  .thumbnail-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .thumbnail-item {
    margin: 0.5rem;
  }
  
  thumbnail-video{
    margin: 0.5rem;


  }
  thumbnail-video video{
    width: 80px; /* يمكنك تغيير الحجم حسب الحاجة */
    height: 100px;
    cursor: pointer;
    border: 2px solid transparent;

  }
  .thumbnail-item img {
    width: 100px; /* يمكنك تغيير الحجم حسب الحاجة */
    height: 80px;
    cursor: pointer;
    border: 2px solid transparent;
  }
  .thumbnail-video video{
    width: 80px; /* يمكنك تغيير الحجم حسب الحاجة */
    height: 100px;
    cursor: pointer;
    border: 2px solid transparent;
  }
  
  .active-thumbnail {
    border-color: #7f7967; /* لون الحدود عند التحديد */
  }
 

/* تنسيق الصورة الرئيسية */
.main-image img {
  max-width: 100%;
  height: auto;
  border: 5px solid #ccc; /* حواف للصورة الرئيسية */
  border-radius: 15px; /* زوايا دائرية للصورة الرئيسية */
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3); /* ظل للصورة الرئيسية */
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}

.main-image img.loaded {
  opacity: 1;
}

.main-image img:hover {
  transform: scale(1.05); /* تكبير الصورة الرئيسية عند التمرير */
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.5); /* تكثيف الظل عند التمرير */
  border-color: #7f7967; /* تغيير لون الحافة عند التمرير */
}

/* تنسيق الصور المصغرة */
.thumbnail-gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.thumbnail-item {
  margin: 10px; /* تباعد بين الصور المصغرة */
}

.thumbnail-item img {
  width: 120px;
  height: auto;
  border: 3px solid #ccc; /* حواف للصور المصغرة */
  border-radius: 10px; /* زوايا دائرية للصور المصغرة */
  transition: transform 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

.thumbnail-item img:hover {
  transform: scale(1.1); /* تكبير الصور المصغرة عند التمرير */
  border-color: #7f7967; /* تغيير لون الحافة عند التمرير */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4); /* ظل خاص عند التمرير */
}

/* تنسيق الصورة النشطة (المختارة) */
.active-thumbnail {
  border: 3px solid #7f7967; /* لون الحافة للصورة النشطة */
  box-shadow: 0px 8px 25px rgba(252, 149, 5, 0.5); /* ظل خاص للصورة النشطة */
}
@media (max-width: 768px) {
  .thumbnail-item img {
      width: 50px;
      height: 40px;
  }
}

