.partners-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: 20px;
    width: 100%; /* تأكد من امتداد الحاوية بعرض كامل الشاشة */
}

.partner-row {
    display: flex;
    /* width: 200%;  */
    overflow: hidden; /* إخفاء العناصر الزائدة */
    white-space: nowrap; /* منع التفاف العناصر */
}

.partner-logo {
    width: 250px;
    height: auto;
    margin: 0 15px;
}


@media (max-width: 768px) {
    .partner-row {
        flex-direction: row;
    }

  

    .partner-logo {
        width: 80px;
        margin: 0 10px; 
    }
}

@media (max-width: 480px) {
    .partner-logo {
        width: 60px; /* تصغير حجم الصور أكثر للشاشات الأصغر */
        margin: 0 8px; /* تقليل المسافات بين الصور */
    }
}



.header1-l1 {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1rem;
    
}

.between-borders1-l1 {
    position: absolute;
    text-align: center;
    padding: 0 20px; 
    font-family: Cairo;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
        padding: 0 20px; 
}

.between-borders1-l1::before, .between-borders1-l1::after {
    content: '';
    position: absolute;
    top: 50%; 
    width: 50%;
    height: 3px;
    background-color: #858585a1;
}

.between-borders1-l1::before {
    right: 100%;
}

.between-borders1-l1::after {
    left: 100%;
}

@media (max-width: 576px) {
    .between-borders1-l1 {
        font-size: 24px;
        padding: 0 10px; 
    }

    .between-borders1-l1::before, .between-borders1-l1::after {
        width: 60%; 
    }
}

@media (max-width: 768px) {
    .between-borders1-l1::before, .between-borders1-l1::after {
        width:30%; 
    }
}

@media (min-width: 1200px) {
    .between-borders1-l1::before, .between-borders1-l1::after {
        width: 100%; 
    }
}