/* الوضع الافتراضي (وضع النهار) */
.navbar {

  background: #e3dfdc;
}
.navbar-custom {
  background-color: #e3dfdc;
  color: #000000;
}

.navbar-custom .nav-link {
  color: #000000;
}

.navbar-custom .navbar-brand img {
  filter: none; /* لا تطبق أي تأثيرات على الصور */
}

/* وضع الليل (الوضع الداكن) */
.navbar-dark-mode {
  background-color: #1e2120;
  color: #ffffff;
}

.navbar-dark-mode .nav-link {
  color: #ffffff !important;
}

.navbar-dark-mode .navbar-brand img {
  filter: invert(100%);
}

.btn-outline-light {
  color: #ffffff;
  border-color: #ffffff;
}
.navbar-nav .nav-link {
  color: rgba(0, 0, 0, .55);
  font-size:medium;
}


.navbar {

  --bs-navbar-padding-y: 0.1rem;
border-bottom-left-radius: 30px ;
border-bottom-right-radius: 30px;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #766155;
}
.navbar-light {

  color: #766155;

}