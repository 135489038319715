.container {
    margin: 0 auto;
  }
  
  /* التصميم الرئيسي للصورة */
  .main-image-section {
    padding: 1rem;
    display: flex;
    justify-content: center;
    border-top: 4px solid #fff;
    border-bottom: 4px solid #fff;
  }
  
  /* تصميم الفيديوهات في 4 أعمدة على الشاشات الكبيرة */
  .video-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
  
  .video-item {
    width: 100%;
    border: 2px solid #bdbdbd;
    border-radius: 8px;

  }
  .image-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    padding: 2rem 0;
}
.image-gallery img
{width: 200px;
  height: auto;
  borderRadius: 8px;
  margin: '10px';
  cursor: 'pointer';
  }
  @media (max-width: 768px) {
    .video-grid {
      display: flex; /* استخدام flexbox بدلاً من grid */
      overflow-x: auto; /* تمكين التمرير الأفقي */
      white-space: nowrap; /* عدم التفاف العناصر */
      gap: 1rem;
    }
  
    .video-item {
      flex: 0 0 auto; /* جعل الفيديو لا يتمدد بشكل كامل */
      width: 50%;
    }
    .image-gallery {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      padding: 2rem 0;
  }
  .image-gallery img
{width: 170px;
  height: auto;
  borderRadius: 8px;
  margin: '10px';
  cursor: 'pointer';
  }
  }
 