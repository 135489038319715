.footer {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    font-family: cairo;
  }
  
  .footer-dark-mode {
    background: #1e2120;
    color: #ffffff;
  }
  
  .footer-light-mode {
    background: #e3dfdc;
    color: #000000;
  }
  
  .footer-top {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .footer-logo {
    width: 30%;
    max-width: 200px;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  
  .footer-mission {
    margin: 1rem 0;
  }
  
  .footer-mission p {
    font-size: 1rem;
    color: inherit; /* Inherit color from parent */
  }
  
  .footer-slogan-footer {
    width: 40%;
    max-width: 150px;
  }
  
  .footer-social-icons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .footer-social-icons a {
    color: inherit;
    text-decoration: none;
  }
  
  .footer-social-icons a:hover {
    color: #007bff;
  }
  
  .footer-help-support {
    margin-bottom: 1rem;
  }
  
  .footer-help-support h6 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .footer-help-support ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-help-support ul li {
    margin-bottom: 0.5rem;
  }
  
  .footer-help-support ul li a {
    color: inherit;
    text-decoration: none;
  }
  
  .footer-help-support ul li a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    background: #000000;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  .footer-bottom p {
    margin: 0;
    font-size: 0.9rem;
  }
  
  .footer-bottom img {
    width: 40%;
    max-width: 150px;
  }
  
  @media (max-width: 768px) {
    .footer-logo {
      width: 40%;
    }
  
    .footer-social-icons {
      gap: 0.5rem;
    }
  
    .footer-bottom {
      flex-direction: column;
      align-items: center;
      padding-left: 0rem;
      padding-right: 0rem;
    }
  
    .footer-slogan-footer {
      width: 50%;
      max-width: 200px;
    }
  }
  
  .rtl {
    direction: rtl;
    text-align: right;
  }
  
  .ltr {
    direction: ltr;
    text-align: left;
  }
  