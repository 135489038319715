:root {
  --background-color-light: #9a3030;
  --background-color-dark: #1e2120;
  --text-color-light: #000000;
  --text-color-dark: #ffffff;
  --padding-color-light: #f5f5f5; /* Color for padding in light mode */
  --padding-color-dark: #333333;  /* Color for padding in dark mode */
}

/* تطبيق الأنماط بناءً على الوضع المحدد */
[data-theme="dark"] {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
  padding-color: var(--padding-color-dark);
}

[data-theme="light"] {
  background-color: var(--background-color-light);
  color: var(--text-color-light);
  padding-color: var(--background-color-light);
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body {
  scroll-behavior: smooth;
}
