.address-details {
    font-family: 'Cairo', sans-serif;
    padding: 20px;
    border: 4px solid #ccc;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 4.5rem;
    margin-bottom: 1rem;
    border-radius: 30px;
    text-align: left;
  
    &.rtl {
      direction: rtl;
      text-align: right;
    }
  
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
  
    .address-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      gap: 10px;
  
      .address-label {
        font-weight: bold;
        flex: 1;
      }
  
      .address-value {
        flex: 2;
        padding: 10px;
        border: 1px solid #ddd;
      }
    }
  }
  